<nb-icon
  [nbPopover]="basicExplanationTemplate"
  class="cursor-pointer info-color"
  nbPopoverTrigger="click"
  icon="question-mark-circle-outline"
  status="info"
  pack="eva">
</nb-icon>

<ng-template #basicExplanationTemplate>
  <nb-card class="container">
    <nb-card-header *ngIf="popupTitle">
      <strong>{{popupTitle}}</strong>
    </nb-card-header>
    <nb-card-body *ngIf="popupText">
      <p>{{popupText}}</p>
    </nb-card-body>
    <nb-card-footer *ngIf="popupFooter">
      <p>{{popupFooter}}</p>
    </nb-card-footer>
  </nb-card>
</ng-template>
