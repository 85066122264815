import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

declare let initCookieConsent: any;

@Component({
  selector: 'pv-pv-recruitment-common-footer',
  templateUrl: './pv-recruitment-common-footer.component.html',
  styleUrls: ['./pv-recruitment-common-footer.component.scss']
})
export class PvRecruitmentCommonFooterComponent implements OnInit {
  cookieConsent: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.initializeCookieConsent();

    setTimeout(() => {
      this.route.queryParams.subscribe(params => {
        if (params.showPreferences) {
          this.openCookieSettings();
        }
      });
    }, 1000)
  }

  initializeCookieConsent(): void {
    this.cookieConsent = initCookieConsent();
  }

  openCookieSettings(): void {
    if (this.cookieConsent) {
      this.cookieConsent.showSettings();
    } else {
      console.error('Cookie Consent n\'est pas encore chargé !');
    }
  }
}
