<common-card class="modal--small">
  <common-card-header>
    {{ 'modules.valet.kaze_phone_modal.already_taken_title' | translate }}
  </common-card-header>
  <common-card-body>
    <p>{{ 'modules.valet.kaze_phone_modal.phone_taken_1' | translate }} <strong>{{ phone+'' }}</strong> {{ 'modules.valet.kaze_phone_modal.phone_taken_2' | translate }}</p>
    <ul>
      <li><strong>{{ 'modules.valet.kaze_phone_modal.our_phone_1' | translate }}&nbsp;: </strong>{{ 'modules.valet.kaze_phone_modal.our_phone_2' | translate }}</li>
      <li><strong>{{ 'modules.valet.kaze_phone_modal.kaze_phone_1' | translate }}&nbsp;: </strong>{{ 'modules.valet.kaze_phone_modal.kaze_phone_2' | translate }}</li>
    </ul>
    <p>{{ 'modules.valet.kaze_phone_modal.contact_us' | translate }}</p>
  </common-card-body>
  <common-card-footer class="d-flex justify-content-end">
    <button nbButton (click)="close()">
      {{ 'global.button.understood' | translate}}
    </button>
  </common-card-footer>
</common-card>
