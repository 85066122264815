import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {USER_ROLE} from '../../../../../shared/models/user';
import {VALET_DOCUMENT_TYPE, ValetDocument, ValetDocuments} from '../../../models/valet-document';
import {TranslateService} from '@ngx-translate/core';
import {Valet} from 'src/app/admin/modules/admin-valet/models/valet';
import {AdminValetService} from '../../../../../admin/modules/admin-valet/services/admin-valet.service';
import {UserIdentityCheck} from '../../../../../pv-modules/pv-recruitment/models/UserIdentityCheck';
import {
  AdminRecrutementService
} from '../../../../../admin/modules/admin-recrutement/services/admin-recrutement.service';
import {Candidate, Step} from '../../../../../pv-modules/pv-recruitment/models/Candidate';
import {ValetBlock} from '../../../../../admin/models/ValetBlock';

@Component({
  selector: 'pv-admin-valet-documents',
  templateUrl: './common-valet-documents.component.html',
  styleUrls: ['./common-valet-documents.component.scss']
})
export class CommonValetDocumentsComponent implements OnInit {
  public USER_ROLE = USER_ROLE;
  public allDocuments = [];
  public documents: ValetDocuments;
  public documentsProgress = 0;
  public generateContractLoading = false;
  public idCheckStatus: UserIdentityCheck;

  @Input() viewAs: USER_ROLE;
  @Input() valet: Valet;
  @Input() hideContract = false;
  @Input() candidate: Candidate;
  @Input() valetBlock: ValetBlock;
  @Input() unavailableIdDocumentCheck = false;

  @Input()
  set documentsData(documents: ValetDocuments) {
    if (documents) {
      this.documents = documents;
      if (this.allDocuments.length > 0) {
        this.updateProgressBar();
      }
    }
  }

  @Input('idCheckStatus')
  set setIdCheckStatus(idCheckStatus: UserIdentityCheck | null) {
    if (idCheckStatus) {
      this.idCheckStatus = idCheckStatus;
      const idCard = this.allDocuments.find(d => d.key === VALET_DOCUMENT_TYPE.idCard);
      if (idCard) {
        idCard.rejectedReasons = this.idCheckStatus.idDocumentCheckRejectedReasons;
      }
      const drivingLicense = this.allDocuments.find(d => d.key === VALET_DOCUMENT_TYPE.drivingLicense);
      if (drivingLicense) {
        drivingLicense.rejectedReasons = this.idCheckStatus.idVerificationCheckRejectedReasons;
      }
      const proofOfAddress = this.allDocuments.find(d => d.key === VALET_DOCUMENT_TYPE.proofOfAddress);
      if (proofOfAddress) {
        proofOfAddress.rejectedReasons = this.idCheckStatus.poaCheckRejectReasons;
      }
      const rib = this.allDocuments.find(d => d.key === VALET_DOCUMENT_TYPE.rib);
      if (rib) {
        rib.rejectedReasons = this.idCheckStatus.ibanCheckRejectReasons;
      }
    }
  }

  @Output() documentUpdated = new EventEmitter<ValetDocument>();
  @Output() contractGenerated = new EventEmitter();
  @Output() siretValidated = new EventEmitter();

  constructor(private translateService: TranslateService,
              private adminValetService: AdminValetService,
              private adminRecruitmentService: AdminRecrutementService) {
  }

  ngOnInit() {
    const documentsForIdCheckOnly = this.viewAs === USER_ROLE.ADMIN && this.candidate?.step === Step.idCheck;
    this.allDocuments = [{
      title: this.translateService.instant('modules.valet.valet_documents.document_items.id_card'),
      key: VALET_DOCUMENT_TYPE.idCard
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.id_card_verso'),
      key: VALET_DOCUMENT_TYPE.idCardVerso
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.proof_of_address'),
      key: VALET_DOCUMENT_TYPE.proofOfAddress,
      disabled: documentsForIdCheckOnly
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.driving_license'),
      key: VALET_DOCUMENT_TYPE.drivingLicense
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.driving_license_verso'),
      key: VALET_DOCUMENT_TYPE.drivingLicenseVerso
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.extract_kbis'),
      key: VALET_DOCUMENT_TYPE.extractKbis,
      disabled: documentsForIdCheckOnly,
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.rib'),
      key: VALET_DOCUMENT_TYPE.rib,
      disabled: documentsForIdCheckOnly
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.insurance'),
      key: VALET_DOCUMENT_TYPE.insurance,
      disabled: documentsForIdCheckOnly,
      requireExpiryDate: true
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.certificate_urssaf'),
      key: VALET_DOCUMENT_TYPE.certificateURSSAF,
      disabled: documentsForIdCheckOnly,
      optional: true,
      requireEmittedDate: true
    }, {
      title: this.translateService.instant('modules.valet.valet_documents.document_items.vital_card'),
      key: VALET_DOCUMENT_TYPE.vitalCard,
      disabled: true,
      optional: true
    }];

    if (!this.candidate || this.candidate.nationality === 'foreign') {
      this.allDocuments.push({
        title: this.translateService.instant('modules.valet.valet_documents.document_items.residence_permit'),
        key: VALET_DOCUMENT_TYPE.residencePermit,
        optional: !this.candidate,
        ifApplicable: true,
        requireExpiryDate: true
      })
    }

    if (this.documents) {
      this.updateProgressBar();
    }
  }

  private updateProgressBar() {
    const mandatoryDocuments = this.allDocuments.filter(doc => !doc.optional && !doc.disabled);
    const oneDocumentPercent = 100 / mandatoryDocuments.length;
    let allDocumentsPercent = 0;
    mandatoryDocuments.forEach(document => {
      if (this.documents[document.key]) {
        allDocumentsPercent += oneDocumentPercent;
      }
    });
    this.documentsProgress = Math.round(allDocumentsPercent);
  }

  public documentUpdateHandler(event: ValetDocument) {
    console.log(event);
    if (event.documentType in this.documents) {
      this.documents[event.documentType] = Object.assign(
        {},
        this.documents[event.documentType],
        event.url ? {
          url: event.url
        } : {}
      );
    } else {
      this.documents[event.documentType] = event;
    }
    this.documents[event.documentType].isSubmitting = true;
    this.documentUpdated.emit(event);
  }

  public generateContract() {
    this.generateContractLoading = true;
    this.adminValetService.generateContract(this.valet.id).then(() => {
      this.contractGenerated.emit();
    }).finally(() => {
      this.generateContractLoading = false;
    });
  }

  public getGenerateContractButtonText() {
    if (this.valet?.contractTransactionId) {
      return this.translateService.instant('modules.valet.valet_documents.regenerate_signature');
    } else {
      return this.translateService.instant('modules.valet.valet_documents.generate_signature')
    }
  }

  public validateSiret() {
    this.adminRecruitmentService.validateSiret(this.valet.id).finally(() => {
      this.siretValidated.emit();
    });
  }
}
