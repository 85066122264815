<common-card style="width: 900px">
  <common-card-header>
    <h5>{{ 'auth.first_connection.welcome' | translate: { firstName: valet.firstName, lastName: valet.lastName } }}</h5>
  </common-card-header>
  <common-card-body>
    <p>{{ 'auth.first_connection.account_active' | translate }}</p>
    <p>{{ 'auth.first_connection.gps_data' | translate }}</p>
    <p>{{ 'auth.first_connection.contact_us_text_1' | translate }} <a href="https://www.popvalet.com/popvalet-general-contact" target="_blank">{{ 'auth.first_connection.contact_us_link' | translate }}</a> {{ 'auth.first_connection.contact_us_text_2' | translate }}</p>
    <p>{{ 'auth.first_connection.requested_data' | translate }}</p>
    <p>{{ 'auth.first_connection.privacy_policy_text' | translate }} <a href="https://www.popvalet.com/privacy-policy">{{ 'auth.first_connection.privacy_policy_link' | translate }}</a>.</p>
    <button class="mt-3" nbButton fullWidth status="primary" (click)="understoodValidation()">
      {{ 'auth.first_connection.understood_button' | translate }}
    </button>
  </common-card-body>
</common-card>
