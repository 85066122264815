import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valetIsDisabled'
})
export class ValetIsDisabledPipe implements PipeTransform {

  transform(log: any): boolean {
    return (log.active === false || log.ready === false) && (log.disableReason || log.disableComment);
  }

}
