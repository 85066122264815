import { Pipe, PipeTransform } from '@angular/core';

export interface Admin {
  firstName: string;
  lastName: string;
}

@Pipe({
  name: 'profileActionAdmin'
})
export class ProfileActionAdminPipe implements PipeTransform {

  transform(admin: Admin): string {
    if (admin) {
      return admin.firstName + ' ' + admin.lastName;
    } else {
      return 'Automatique';
    }
  }

}
