import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {USER_ROLE} from '../../../../../shared/models/user';
import {Metropolis} from '../../../../../admin/modules/admin-global/models/metropolis';
import {Valet} from '../../../../../admin/modules/admin-valet/models/valet';
import {ToastHandlerService} from '../../../../../shared/services/toast-handler.service';
import {TranslateService} from '@ngx-translate/core';
import {GoogleAddressParser} from '../../../../../shared/classes/google-address-parser';
import {ACCEPTED_PROFILE_PICTURE_FILE_TYPES} from '../../../../../shared/constants/constants';

@Component({
  selector: 'pv-common-valet-profile-info',
  templateUrl: './common-valet-profile-info.component.html',
  styleUrls: ['./common-valet-profile-info.component.scss']
})
export class CommonValetProfileInfoComponent implements OnInit {

  constructor(private toastService: ToastHandlerService,
              private translateService: TranslateService,
              private fb: FormBuilder) { }
  public ROLE = USER_ROLE;
  public file: File;
  public profilePictureForm: FormGroup;
  public profilePicturePreview: string | ArrayBuffer | null = null;
  public acceptedProfilePictureFileTypes = ACCEPTED_PROFILE_PICTURE_FILE_TYPES;
  public showProfilePictureButton = false;

  @Input() valet: Valet;
  @Input() availableTitles: Promise<any>;
  @Input() availableMetropolis: Metropolis[] = [];
  @Input() valetForm: FormGroup;
  @Input() viewAs: USER_ROLE;
  @Output() submitProfilePictureForm = new EventEmitter();

  ngOnInit(): void {
    this.profilePictureForm = this.fb.group({
      profilePictureFile: this.fb.group({
        file: ['', [Validators.required]]
      }),
    });
  }

  public copyReferralCode() {
    navigator.clipboard.writeText(this.valet.referralCode).then(() => {
      this.toastService.showSuccessToast(
        this.translateService.instant('modules.valet.valet_profile.copy_referral_code_success')
      );
    }).catch(error => {
      this.toastService.showErrorToasts(error.message);
    });
  }

  public emailReferralCode() {
    const subject = 'Code de parrainage Pop Valet';
    const body = 'Bonjour,\nVoici mon code de parrainage Pop Valet : ' + this.valet.referralCode +
      ' que tu peux utiliser en t\'inscrivant ici : https://recrutement.popvalet.com/';
    window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`, '_blank');
  }

  public copyAddressesIfAllowed() {
    if (this.valetForm.get('copyAddresses').value === true) {
      this.valetForm.get('billingAddress').setValue(this.valetForm.get('address').value);
      this.valetForm.get('billingCity').setValue(this.valetForm.get('city').value);
      this.valetForm.get('billingPostalCode').setValue(this.valetForm.get('postalCode').value);
      this.valetForm.get('billingCountry').setValue(this.valetForm.get('country').value);
    }
  }

  public copyAddresses(checked: boolean) {
    if (checked) {
      this.valetForm.get('billingAddress').setValue(this.valetForm.get('address').value);
      this.valetForm.get('billingCity').setValue(this.valetForm.get('city').value);
      this.valetForm.get('billingPostalCode').setValue(this.valetForm.get('postalCode').value);
      this.valetForm.get('billingCountry').setValue(this.valetForm.get('country').value);
    }
  }

  getSourcingName() {
    if (this.valet.candidate.sourcingType === 'wordOfMouth') {
      return 'Bouche à oreille'
    } else if (this.valet.candidate.sourcingType === 'facebook') {
      return 'Facebook'
    } else if (this.valet.candidate.sourcingType === 'popvaletWebsite') {
      return 'Site Pop Valet'
    } else if (this.valet.candidate.sourcingType === 'google') {
      return 'Google'
    } else if (this.valet.candidate.sourcingType === 'assuranceAuto') {
      return 'Assurance Auto'
    } else if (this.valet.candidate.sourcingType === 'other') {
      return 'Autre'
    }
  }

  public addressChanged(place: any) {
    if (!place) {
      return null;
    }
    const parsedAddress = GoogleAddressParser.parseAddress(place);
    this.valetForm.patchValue({
      addressLabel: parsedAddress.label,
      address: ((parsedAddress.number || '') + ' ' + (parsedAddress.street || ''))?.trim() || '',
      postalCode: parsedAddress.postalCode,
      city: parsedAddress.city,
      country: parsedAddress.country
    });
    this.copyAddressesIfAllowed();
  }

  public fileChanged(event) {
    const file = event.target.files[0];
    this.profilePictureForm.get('profilePictureFile').get('file').setValue(file);

    const reader = new FileReader();
    reader.onload = () => {
      this.profilePicturePreview = reader.result;
    };
    reader.readAsDataURL(file);

    if (this.profilePictureForm.valid) {
      this.showProfilePictureButton = true;
    }
  }

  public submitProfilePicture() {
    const formData = this.profilePictureForm.getRawValue();
    this.submitProfilePictureForm.emit(formData);
    this.showProfilePictureButton = false;
  }
}
