import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {ToastHandlerService} from '../../shared/services/toast-handler.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class ValetService {

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              private toastService: ToastHandlerService) { }

  public get360learningSsoUrl(): Promise<any> {
    return this.http.get(`${environment.backendUrl}360learning/sso-url`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public updateValetProfilePicture(valetId: string, profilePictureData): Promise<User> {
    const formData = new FormData();
    if (profilePictureData.profilePictureFile) {
      formData.append('profilePictureFile', profilePictureData.profilePictureFile.file as File);
    }
    return this.http.put(`${environment.backendUrl}valets/update-profile-picture/${valetId}`,
      formData)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.valet.services.valet.valet_profile_picture_updated_success')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public validateFirstConnection(): Promise<any> {
    return this.http.put(`${environment.backendUrl}valets/validate-first-connection`,
      {})
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }
}
