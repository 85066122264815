import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../shared/models/user';
import {NbDialogRef} from '@nebular/theme';
import {ValetService} from '../../valet/services/valet.service';
import {UpdateUser} from '../../shared/store/user/auth.actions';
import {Store} from '@ngrx/store';
import {UserState} from '../../shared/store/user/auth.state';

@Component({
  selector: 'pv-common-first-connection-dialog',
  templateUrl: './common-first-connection-dialog.component.html',
  styleUrls: ['./common-first-connection-dialog.component.scss']
})
export class CommonFirstConnectionDialogComponent implements OnInit {
  @Input() valet: User;
  @Output() submitEvent = new EventEmitter();

  constructor(protected dialogRef: NbDialogRef<CommonFirstConnectionDialogComponent>,
              private store: Store<UserState>,
              private valetService: ValetService) { }

  ngOnInit(): void {
  }

  understoodValidation() {
    this.valetService.validateFirstConnection().then(res => {
      this.store.dispatch(new UpdateUser(res));
      this.dialogRef.close();
    })
  }
}
