<common-card class="d-flex w-100">
  <common-card-header>
    <div class="row no-gutters">
      <div class="col">
        <h6>
          {{cardHeader}} {{ifApplicable && optional ? ('modules.valet.valet_documents.optional' | translate) : ''}}<span *ngIf="!optional" class="required-document">*</span>
        </h6>
      </div>
    </div>
  </common-card-header>
  <common-card-body class="d-flex flex-column h-100 align-items-center">

    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="viewAs === ROLES.ADMIN">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="form-group">
              <div class="d-flex">
                <div class="w-100">
                  <input
                    fullWidth
                    nbInput
                    [value]="fileName"
                    readonly
                    placeholder="Ajouter un fichier"
                    type="text"
                    (click)="allowUpload ? fileInput.click() : ''">
                  <input type="file"
                         class="d-none"
                         #fileInput
                         [accept]="acceptedValetFileTypes"
                         (change)="fileInputChanged($event.target.files[0])">
                </div>
                <a class="ml-1" [href]="document?.url" target="_blank" nbButton>
                  <nb-icon icon="download"></nb-icon>
                </a>
              </div>
            </div>
            <div class="row" *ngIf="requireEmittedDate">
              <div class="col-12">
                <div class="form-group">
                  <label class="label" [ngClass]="optional ? '' : 'required'">
                    {{'modules.valet.valet_documents.emitted_date' | translate}}
                  </label>
                  <input [nbDatepicker]="emittedDatepicker"
                         nbInput
                         fullWidth
                         readonly
                         [ngModel]="emittedDate">
                  <nb-datepicker #emittedDatepicker
                                 [format]="'DD.MM.YYYY'"
                                 [max]="today"
                                 (dateChange)="emittedDateChanged($event)">
                  </nb-datepicker>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="requireExpiryDate">
              <div class="col-12">
                <div class="form-group">
                  <label class="label required">
                    {{'modules.valet.valet_documents.expiry_date' | translate}}
                  </label>
                  <input [nbDatepicker]="expiryDatepicker"
                         nbInput
                         fullWidth
                         readonly
                         [ngModel]="expiryDate">
                  <nb-datepicker #expiryDatepicker
                                 [format]="'DD.MM.YYYY'"
                                 (dateChange)="expiryDateChanged($event)">
                  </nb-datepicker>
                </div>
              </div>
            </div>
            <div [formGroup]="form">
              <textarea
                *ngIf="invalidStatus"
                nbInput
                fullWidth
                (change)="fileValidationChanged(DOCUMENT_STATUS.INVALID)"
                formControlName="comment">
              </textarea>
              <textarea
                *ngIf="document && document.comment && (document.status === DOCUMENT_STATUS.VALID || document.status === DOCUMENT_STATUS.INVALID)"
                nbInput
                fullWidth
                disabled
                formControlName="comment">
              </textarea>
            </div>
            <div
              *ngIf="document && document.status === DOCUMENT_STATUS.PENDING; else notChecked"
              class="row justify-content-center mt-2 mb-3">
              <div class="col-auto">
                <button nbButton status="danger"
                        (click)="fileValidationChanged(DOCUMENT_STATUS.INVALID)">
                  <nb-icon icon="times"></nb-icon>
                </button>
              </div>
              <div class="col-auto">
                <button nbButton status="success"
                        (click)="fileValidationChanged(DOCUMENT_STATUS.VALID)">
                  <nb-icon icon="check"></nb-icon>
                </button>
              </div>
            </div>
            <ng-container *ngIf="document && document.status === DOCUMENT_STATUS.PENDING">
              <div *ngIf="this.validStatus" class="text-success text-center mt-2">
                {{'modules.valet.valet_documents.document_is_valid' |translate}}
              </div>
              <div *ngIf="this.invalidStatus" class="text-danger text-center mt-2">
                {{'modules.valet.valet_documents.document_is_not_valid' |translate}}
              </div>
            </ng-container>
            <div class="row justify-content-center">
              <div class="col-auto">
                <button nbButton size="small"
                        [disabled]="!canUploadFile()"
                        (click)="uploadFile()">
                  {{'modules.valet.valet_documents.upload' |translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="rejectedReasons?.length" class="mt-2">
          {{'modules.valet.valet_documents.rejected_automatically' |translate}}<br>
          <span *ngFor="let reason of rejectedReasons"> - {{reason}}<br></span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="viewAs === ROLES.VALET || viewAs === ROLES.CANDIDATE">
        <div class="row w-100 justify-content-center">
          <div class="col-12">
            <div class="form-group">
              <div class="d-flex">
                <div class="w-100">
                  <input
                    fullWidth
                    nbInput
                    [value]="fileName"
                    readonly
                    placeholder="Ajouter un fichier"
                    (click)="allowUpload ? fileInput.click() : ''"
                    type="text">
                  <input type="file"
                         class="d-none"
                         #fileInput
                         [accept]="acceptedValetFileTypes"
                         (change)="fileInputChanged($event.target.files[0])">
                </div>
                <div *ngIf="document?.url && document?.status" class="ml-1">
                  <a [href]="document.url" target="_blank" nbButton>
                    <nb-icon icon="download"></nb-icon>
                  </a>
                </div>
              </div>

            </div>
          </div>
          <div class="col-12" *ngIf="requireEmittedDate">
            <div class="form-group">
              <label class="label" [ngClass]="optional ? '' : 'required'">
                {{'modules.valet.valet_documents.emitted_date' | translate}}
              </label>
              <input [nbDatepicker]="emittedDatepicker"
                     nbInput
                     fullWidth
                     readonly
                     [ngModel]="emittedDate">
              <nb-datepicker #emittedDatepicker
                             [format]="'DD.MM.YYYY'"
                             [max]="today"
                             (dateChange)="emittedDateChanged($event)">
              </nb-datepicker>
            </div>
          </div>
          <div class="col-12" *ngIf="requireExpiryDate">
            <div class="form-group">
              <label class="label required">
                {{'modules.valet.valet_documents.expiry_date' | translate}}
              </label>
              <input [nbDatepicker]="expiryDatepicker"
                     nbInput
                     fullWidth
                     readonly
                     [ngModel]="expiryDate">
              <nb-datepicker #expiryDatepicker
                             [format]="'DD.MM.YYYY'"
                             (dateChange)="expiryDateChanged($event)">
              </nb-datepicker>
            </div>
          </div>
          <div *ngIf="document?.comment" class="col-12 mb-2">
              <textarea
                nbInput
                fullWidth
                disabled
                [value]="document.comment">
              </textarea>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <ng-container *ngIf="document">
                <div class="col" [ngSwitch]="document.status">
                  <div class="text-success" *ngSwitchCase="DOCUMENT_STATUS.VALID">
                    {{'modules.valet.valet_documents.validation.valid' |translate}}
                  </div>
                  <div class="text-danger" *ngSwitchCase="DOCUMENT_STATUS.INVALID">
                    {{'modules.valet.valet_documents.validation.invalid' |translate}}
                  </div>
                  <div class="text-danger" *ngSwitchCase="DOCUMENT_STATUS.EXPIRED">
                    {{'modules.valet.valet_documents.validation.expired' |translate}}
                  </div>
                  <div class="text-warning" *ngSwitchCase="DOCUMENT_STATUS.PENDING">
                    {{'modules.valet.valet_documents.validation.pending' |translate}}
                  </div>
                </div>
              </ng-container>
              <div class="col-auto">
                <button nbButton size="small"
                        [disabled]="!canUploadFile()"
                        (click)="uploadFile()">
                  {{'modules.valet.valet_documents.send' |translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <span *ngSwitchDefault>
        {{'modules.valet.valet_documents.no_supported_role' |translate}}
      </span>
    </ng-container>
  </common-card-body>
</common-card>


<ng-template #notChecked>
  <ng-container [ngSwitch]="document?.status">
    <div *ngSwitchCase="DOCUMENT_STATUS.VALID" class="text-success text-center mt-2">
      {{'modules.valet.valet_documents.document_is_valid' |translate}}
    </div>
    <div *ngSwitchCase="DOCUMENT_STATUS.INVALID" class="text-danger text-center mt-2">
      {{'modules.valet.valet_documents.document_is_not_valid' |translate}}
    </div>
  </ng-container>
</ng-template>

