import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonValetProfileFormDocumentComponent } from './components/common-valet-profile-form/common-valet-profile-form-document/common-valet-profile-form-document.component';
import { CommonValetProfileFormComponent } from './components/common-valet-profile-form/common-valet-profile-form.component';
import { CommonCardModule } from '../common-card/common-card.module';
import { SharedModule } from '../../shared/shared.module';
import {
    NbAlertModule,
    NbCardModule,
    NbDatepickerModule,
    NbIconModule,
    NbProgressBarModule,
    NbSelectModule
} from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { CommonValetDocumentsComponent } from './components/common-valet-profile-form/common-valet-documents/common-valet-documents.component';
import { CommonTranslateModule } from '../common-translate/common-translate.module';

import {CommonValetEvaluationComponent} from './components/common-valet-profile-evaluation/common-valet-profile-evaluation.component';
import {FormsModule} from '@angular/forms';
import { CommonValetProfileInfoComponent } from './components/common-valet-profile-form/common-valet-profile-info/common-valet-profile-info.component';
import {CommonMapModule} from '../common-map/common-map.module';
import { CommonValetDisabledDialogComponent } from './components/common-valet-profile-form/common-valet-disabled-dialog/common-valet-disabled-dialog.component';
import {CommonPipesModule} from '../common-pipes/common-pipes.module';
import { CommonValetInvoicesComponent } from './components/common-valet-invoices/common-valet-invoices.component';
import {SmartTableBackendWrapperModule} from '@pop-valet/smart-table-backend-wrapper';
import { CommonValetInvoicingBlockDialogComponent } from './components/common-valet-profile-form/common-valet-invoicing-block-dialog/common-valet-invoicing-block-dialog.component';
import { ProfileActionReasonPipe } from './components/common-valet-profile-form/pipes/profile-action-reason.pipe';
import { ValetIsDisabledPipe } from './components/common-valet-profile-form/pipes/valet-is-disabled.pipe';
import { ValetHasBlockedInvoicingPipe } from './components/common-valet-profile-form/pipes/valet-has-blocked-invoicing.pipe';
import { ProfileActionPipe } from './components/common-valet-profile-form/pipes/profile-action.pipe';
import {ProfileActionAdminPipe} from './components/common-valet-profile-form/pipes/profile-action-admin';


@NgModule({
  declarations: [
    CommonValetEvaluationComponent,
    CommonValetProfileFormDocumentComponent,
    CommonValetProfileFormComponent,
    CommonValetDocumentsComponent,
    CommonValetProfileInfoComponent,
    CommonValetDisabledDialogComponent,
    CommonValetInvoicesComponent,
    CommonValetInvoicingBlockDialogComponent,
    ProfileActionReasonPipe,
    ValetIsDisabledPipe,
    ValetHasBlockedInvoicingPipe,
    ProfileActionPipe,
    ProfileActionAdminPipe
  ],
    imports: [
        CommonModule,
        CommonCardModule,
        SharedModule,
        NbIconModule,
        NbSelectModule,
        NbDatepickerModule,
        NbMomentDateModule,
        NbProgressBarModule,
        CommonTranslateModule,
        FormsModule,
        CommonMapModule,
        CommonPipesModule,
        NbCardModule,
        SmartTableBackendWrapperModule,
        NbAlertModule
    ],
  exports: [
    CommonValetProfileFormDocumentComponent,
    CommonValetProfileFormComponent,
    CommonValetDocumentsComponent,
    CommonValetEvaluationComponent,
    CommonValetInvoicesComponent
  ]
})
export class CommonValetModule {
}
