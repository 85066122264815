import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Settings} from '../models/settings';
import {PriceCalculation} from '../models/price';
import {ToastHandlerService} from '../../../shared/services/toast-handler.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class PvReservationService {

  constructor(private http: HttpClient,
              private toastService: ToastHandlerService,
              private translateService: TranslateService) {
  }

  getSettings(publicKey: string, token: string): Observable<Settings> {
    const params: any = {};
    if (token) {
      params.token = token;
    }
    return this.http.get(`${environment.backendUrl}order-embed/get-settings/${publicKey}`, {
      params
    }).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(err => {
        const message = this.translateErrorMessage(err);
        if (message) {
          this.toastService.showErrorToasts(message, undefined, 0);
        } else {
          this.toastService.showErrorToastsResponse(err);
        }
        return throwError(err);
      })
    );
  }

  getClosestRestitutionCenter(publicKey: string, address: any) {
    return this.http.post(`${environment.backendUrl}order-embed/get-closest-restitution-center/${publicKey}`, {
      address
    }).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(err => {
        if (err.error?.errorCode === 'cannot_find_closest_restitution_center') {
          this.toastService.showErrorToasts(
            this.translateService.instant('modules.order.services.cannot_find_closest_restitution_center_error')
          );
        } else {
          this.toastService.showErrorToastsResponse(err);
        }
        return throwError(err);
      })
    ).toPromise();
  }

  createOrder(publicKey: string, orders, billingInfo, payment): Observable<any> {
    const newOrders = [];
    newOrders.push(orders);
    return this.http.post(`${environment.backendUrl}order-embed/create-pending-order`, {
      publicKey, data: {orders: newOrders, billingInfo, payment}
    }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getPrice(publicKey: string, reservationData: any, linkedMission?: any): Observable<PriceCalculation> {
    return this.http.post(`${environment.backendUrl}order-embed/get-order-price`, {
        publicKey,
        mission: reservationData,
        linkedMission
      }
    ).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getTimeSlots(
    publicKey: string,
    order: any,
    reservationData: any,
    linkedMission: any | undefined,
    takeOverTimeSlot: any,
    handOverTimeSlot: any,
    fromDate: string,
    toDate: string
  ): Promise<any> {
    return this.http.post(`${environment.backendUrl}order-embed/get-mission-time-slots`, {
      publicKey,
      order,
      mission: reservationData,
      linkedMission,
      takeOverTimeSlot,
      handOverTimeSlot,
      fromDate,
      toDate
    }).pipe(
      map((res: any) => {
        return res.data;
      })
    ).toPromise();
  }

  getDuration(reservationData: any, linkedMission?: any): Promise<any> {
    return this.http.post(`${environment.backendUrl}order-embed/get-mission-duration`, {
        mission: reservationData,
        linkedMission
      }
    ).pipe(
      map((res: any) => {
        return res.data;
      })
    ).toPromise();
  }

  createQuotation(publicKey: string, token: string, order, billingInfo): Observable<any> {
    const newOrders = [order];
    return this.http.post(`${environment.backendUrl}order-embed/create-quotation`, {
      publicKey,
      token,
      data: {
        orders: newOrders,
        billingInfo
      }
    }).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(err => {
        const message = this.translateErrorMessage(err);
        if (message) {
          this.toastService.showErrorToasts(message);
        } else {
          this.toastService.showErrorToastsResponse(err);
        }
        return throwError(err);
      })
    );
  }

  deleteQuotation(externalOrderId: string): Observable<any> {
    return this.http.delete(`${environment.backendUrl}order-embed/delete-quotation/${externalOrderId}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getOpeningHours(completePlaceName: string) {
    return this.http.post(`${environment.backendUrl}order-embed/opening-hours`, {
      completePlaceName
    })
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.places.services.place_opening_hours_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  private translateErrorMessage(err: any) {
    switch (err.error.errorCode) {
      case 'missing_token':
        return this.translateService.instant('pv_modules.pv_reservation.service.errors.missing_token');
      case 'token_not_found':
        return this.translateService.instant('pv_modules.pv_reservation.service.errors.token_not_found');
      case 'token_already_used':
        return this.translateService.instant('pv_modules.pv_reservation.service.errors.token_already_used');
      default:
        return null;
    }
  }
}
