import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'pv-basic-helper-icon',
  templateUrl: './basic-helper-icon.component.html',
  styleUrls: ['./basic-helper-icon.component.scss']
})
export class BasicHelperIconComponent implements OnInit {
  @Input() popupTitle: string;
  @Input() popupText: string;
  @Input() popupFooter: string;

  constructor() { }

  ngOnInit(): void {
  }

}
