import {UserIdentityCheck} from '../../pv-modules/pv-recruitment/models/UserIdentityCheck';

export interface User {
  id: string;
  roles: USER_ROLE[];
  rolesArray: USER_ROLE[];
  role: USER_ROLE;
  firstLoginAt: string;
  lastLoginAt: string;
  firstConnectionValidated: boolean;
  firstName: string;
  lastName: string;
  status: string;
  customer: any;
  qualityRate: string;
  approvedGeneralTerms?: boolean;
  group: Group | null;
  email: string;
  profilePictureFile: ProfilePictureFile | null;
  identityCheck: UserIdentityCheck;
  lastCallMeRequestDate: any;
}

interface Group {
  id: string;
  users: GroupUser[];
}

export interface GroupUser {
  id: string;
  customer: {
    name: string;
  }
}

export enum USER_ROLE {
  SUPER_ADMIN = 'popvalet-super-admin',
  ADMIN = 'popvalet-admin',
  QUALITY = 'popvalet-quality',
  VALET = 'popvalet-valet',
  CANDIDATE = 'popvalet-candidate',
  CUSTOMER_ADMIN = 'customer-admin',
  CUSTOMER_USER = 'customer-user',
  CUSTOMER_READ_ONLY = 'customer-user-read-only',
}

export interface ProfilePictureFile {
  url: string;
}
