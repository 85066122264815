import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'pv-kaze-phone-already-taken-modal',
  templateUrl: './kaze-phone-already-taken-modal.component.html',
  styleUrls: ['./kaze-phone-already-taken-modal.component.scss']
})
export class KazePhoneAlreadyTakenModalComponent {
  @Input() phone: number;

  constructor(
    protected dialogRef: NbDialogRef<KazePhoneAlreadyTakenModalComponent>
  ) { }

  close() {
    this.dialogRef.close();
  }
}
