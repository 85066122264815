<nb-card class="recruitment-dialog-card" style="position: relative">
  <nb-card-header class="d-flex justify-content-center align-items-center">
    <img src="assets/images/popvalet_icon_valet.png" alt="">
    <h3 class="text-center ml-4">{{'recruitment.popup.eligibility_title' | translate}}</h3>
  </nb-card-header>

  <ng-container *ngIf="formFirstStep">
    <nb-card-body class="dialog-container-center" *ngIf="dialogStep === 0" [formGroup]="formFirstStep">
      <div class="d-flex align-items-center flex-column"
           style="width: fit-content">
        <h4 style="color: white; text-transform: uppercase; font-weight: bold">{{'recruitment.popup.create_account' | translate}}</h4>
        <div class="container container-create-account">
          <div class="row">
            <div class="col-md-2 button-bottom-space">
              <nb-select
                formControlName="title"
                placeholder="{{'recruitment.popup.placeholder.title' | translate}}"
                fullWidth
                [status]="getFormControlStatus(formFirstStep.controls['title'])">
                <nb-option [value]="'Mr'">{{'recruitment.popup.answer_type_mister' | translate}}</nb-option>
                <nb-option [value]="'Mme'">{{'recruitment.popup.answer_type_madam' | translate}}</nb-option>
                <nb-option [value]="'Mlle'">{{'recruitment.popup.answer_type_miss' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formFirstStep.controls['title']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
            <div class="col-md-5 button-bottom-space">
              <input formControlName="firstName" nbInput placeholder="{{'recruitment.popup.placeholder.first_name' | translate}}" fullWidth>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formFirstStep.controls['firstName']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
            <div class="col-md-5">
              <input formControlName="lastName" nbInput placeholder="{{'recruitment.popup.placeholder.last_name' | translate}}" fullWidth>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formFirstStep.controls['lastName']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row" style="margin-top: 30px">
            <div class="col-md-6">
              <pv-shared-input-phone-number
                [formGroup]="formFirstStep"
                controlName="phone">
              </pv-shared-input-phone-number>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formFirstStep.controls['phone']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formFirstStep.controls['phone']"
                  [validation]="'pattern'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
            <div class="col-md-6">
              <input formControlName="email" nbInput type="email" placeholder="{{'recruitment.popup.placeholder.email' | translate}}" fullWidth>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formFirstStep.controls['email']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row" style="margin-top: 30px">
            <div class="col-md-6 button-bottom-space">
              <input formControlName="password" nbInput placeholder="{{'recruitment.popup.placeholder.password' | translate}}" [type]="'password'" fullWidth>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formFirstStep.controls['password']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
            <div class="col-md-6">
              <input formControlName="samePassword" nbInput placeholder="{{'recruitment.popup.placeholder.check_password' | translate}}" [type]="'password'" fullWidth>
            </div>
          </div>

          <div class="row mt-1" style="padding: 0 15px">
            <p style="color: white">{{'recruitment.popup.forms.password_info' | translate}}</p>
          </div>
          <div class="row" style="margin-top: 30px">
            <div class="col-md-6"><input  formControlName="referralCode" nbInput placeholder="{{'recruitment.popup.placeholder.code' | translate}}" fullWidth></div>
          </div>
          <div class="mt-2" style="text-align: center">
            <nb-checkbox status="success" [checked]="useMyData" (checkedChange)="useMyData = !useMyData">
              <p style="color: white; font-weight: bold; text-align: left">{{'recruitment.popup.use_my_data' | translate}}</p>
            </nb-checkbox>
          </div>
        </div>
      </div>
    </nb-card-body>

    <nb-card-body class="dialog-container-center" *ngIf="dialogStep === 1" [formGroup]="formSecondStep">
      <div class="d-flex align-items-center flex-column">
        <h4 style="color: white; text-transform: uppercase; font-weight: bold">{{'recruitment.popup.question_eligibility' | translate}}</h4>
        <div class="container dialog-questions">
          <div class="row" *ngIf="retry === false">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_metropolis' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <pv-common-autocomplete
                formControlName="metropolis"
                [placeholder]="'recruitment.popup.placeholder.metropolis' | translate"
                [selectItems]="availableMetropolis">
              </pv-common-autocomplete>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['metropolis']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_autoentrepreneur' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.yes_or_no' | translate}}"
                formControlName="hasAutoentrepreneurStatus"
                fullWidth
                [status]="getFormControlStatus(formSecondStep.controls['hasAutoentrepreneurStatus'])">
                <nb-option [value]="true">{{'recruitment.popup.answer_yes' | translate}}</nb-option>
                <nb-option [value]="false">{{'recruitment.popup.answer_no' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['hasAutoentrepreneurStatus']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div *ngIf="formSecondStep.controls['hasAutoentrepreneurStatus'].value" class="row">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_tva' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.yes_or_no' | translate}}"
                formControlName="noTVA"
                fullWidth
                [status]="getFormControlStatus(formSecondStep.controls['noTVA'])">
                <nb-option [value]="false">{{'recruitment.popup.answer_yes' | translate}}</nb-option>
                <nb-option [value]="true">{{'recruitment.popup.answer_no' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['noTVA']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div *ngIf="formSecondStep.controls['hasAutoentrepreneurStatus'].value" class="row">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_siret' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <input formControlName="siret"
                     nbInput
                     placeholder="{{'recruitment.popup.placeholder.siret' | translate}}"
                     fullWidth
                     maxlength="14">
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['siret']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['siret']"
                  [validation]="'pattern'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_license_points' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <input nbInput
                     fullWidth
                     formControlName="drivingLicensePoints"
                     type="number"
                     min="0" max="12"
                     (change)="checkLicencePoints()"
                     placeholder="{{'recruitment.popup.placeholder.driving_license_points' | translate}}">
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['drivingLicensePoints']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_license_age' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.driving_license_age' | translate}}"
                formControlName="drivingLicenseAge"
                fullWidth
                [status]="getFormControlStatus(formSecondStep.controls['drivingLicenseAge'])">
                <nb-option [value]="'moreThanOrEqual5'">{{'recruitment.popup.answer_license_age_more' | translate}}</nb-option>
                <nb-option [value]="'lessThan5'">{{'recruitment.popup.answer_license_age_less' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['drivingLicenseAge']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_license_type' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.driving_license_type' | translate}}"
                formControlName="drivingLicenseType"
                fullWidth
                [status]="getFormControlStatus(formSecondStep.controls['drivingLicenseType'])">
                <nb-option [value]="'european'">{{'recruitment.popup.answer_european' | translate}}</nb-option>
                <nb-option [value]="'foreign'">{{'recruitment.popup.answer_foreign' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['drivingLicenseType']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row" *ngIf="retry === false">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_licenses_types' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select fullWidth
                         formControlName="licensesTypes"
                         [multiple]="true"
                         placeholder="{{'recruitment.popup.placeholder.licenses_types' | translate}}">
                <nb-option-group *ngFor="let group of licensesTypesGroups"
                                 [title]="group.name"
                                 style="font-weight: bold;">
                  <nb-option *ngFor="let type of group.types"
                             [value]="type.value">
                    {{type.name}}
                  </nb-option>
                </nb-option-group>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['licensesTypes']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row mt-1" *ngIf="retry === false">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_nationality' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.nationality' | translate}}"
                formControlName="nationality"
                fullWidth
                [status]="getFormControlStatus(formSecondStep.controls['nationality'])">
                <nb-option [value]="'european'">{{'recruitment.popup.answer_european' | translate}}</nb-option>
                <nb-option [value]="'foreign'">{{'recruitment.popup.answer_foreign' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['nationality']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_available_on_saturday' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.yes_or_no' | translate}}"
                formControlName="availableOnSaturday"
                fullWidth
                [status]="getFormControlStatus(formSecondStep.controls['availableOnSaturday'])">
                <nb-option [value]="true">{{'recruitment.popup.answer_yes' | translate}}</nb-option>
                <nb-option [value]="false">{{'recruitment.popup.answer_no' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['availableOnSaturday']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_insurer' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.yes_or_no' | translate}}"
                formControlName="hasInsurer"
                fullWidth
                [status]="getFormControlStatus(formSecondStep.controls['hasInsurer'])">
                <nb-option [value]="true">{{'recruitment.popup.answer_yes' | translate}}</nb-option>
                <nb-option [value]="false" (click)="resetFile()">{{'recruitment.popup.answer_no' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['hasInsurer']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <ng-container [ngSwitch]="formSecondStep.get('hasInsurer').value">
            <div *ngSwitchCase="true">
              <div class="row">
                <div class="col"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_insurer_info' | translate}}</p></div>
              </div>
              <div class="row">
                <button nbButton
                        status="primary"
                        class="ml-3 mb-2"
                        fullWidth
                        (click)="fileInput.click()">
                  {{'recruitment.popup.question_insurer_add_document' | translate}}
                </button>
                <input type="file"
                       class="d-none"
                       #fileInput
                       [accept]="acceptedValetFileTypes"
                       (change)="fileInputChanged($event.target.files[0])">
              </div>
            </div>
            <div *ngSwitchCase="false">
              <div class="row">
                <div class="col"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_insurer_orus_info' | translate}}</p></div>
              </div>
              <div class="row">
                <button nbButton
                        status="primary"
                        class="ml-3 mb-2"
                        fullWidth
                        (click)="redirectToOrusSubscribePage()">
                  {{'recruitment.popup.question_insurer_orus_subscribe' | translate}}
                </button>
              </div>
            </div>
          </ng-container>
          <div class="row" *ngIf="fileName">
            <div class="col"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_insurer_document_added' | translate}} {{fileName}}</p></div>
          </div>
          <div class="row mt-1" *ngIf="retry === false">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_has_w_garage' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.yes_or_no' | translate}}"
                formControlName="hasWGarage"
                fullWidth
                (selectedChange)="updateWGarageValidators($event)"
                [status]="getFormControlStatus(formSecondStep.controls['hasWGarage'])">
                <nb-option [value]="true">{{'recruitment.popup.answer_yes' | translate}}</nb-option>
                <nb-option [value]="false">{{'recruitment.popup.answer_no' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['hasWGarage']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row" *ngIf="retry === false && formSecondStep.get('hasWGarage').value">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_w_garage' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <input nbInput
                     fullWidth
                     formControlName="wGarage"
                     type="text"
                     placeholder="{{'recruitment.popup.placeholder.w_garage' | translate}}">
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['wGarage']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
          </div>
          <div class="row mt-1" *ngIf="retry === false">
            <div class="col-md-8"><p style="color: white; font-weight: bold">{{'recruitment.popup.question_sourcing' | translate}}</p></div>
            <div class="col-md-4 m-auto">
              <nb-select
                placeholder="{{'recruitment.popup.placeholder.sourcing' | translate}}"
                formControlName="sourcingType"
                fullWidth
                [status]="getFormControlStatus(formSecondStep.controls['sourcingType'])">
                <nb-option [value]="'wordOfMouth'">{{'recruitment.popup.answer_sourcing_word_of_mouth' | translate}}</nb-option>
                <nb-option [value]="'facebook'">{{'recruitment.popup.answer_sourcing_facebook' | translate}}</nb-option>
                <nb-option [value]="'popvaletWebsite'">{{'recruitment.popup.answer_sourcing_popvalet_website' | translate}}</nb-option>
                <nb-option [value]="'google'">{{'recruitment.popup.answer_sourcing_google' | translate}}</nb-option>
                <nb-option [value]="'assuranceAuto'">{{'recruitment.popup.answer_sourcing_assurance_auto' | translate}}</nb-option>
                <nb-option [value]="'other'">{{'recruitment.popup.answer_sourcing_other' | translate}}</nb-option>
              </nb-select>
              <slnk-shared-input-validator>
                <slnk-shared-input-validator-item
                  [validationFormControl]="formSecondStep.controls['sourcingType']"
                  [validation]="'required'">
                </slnk-shared-input-validator-item>
              </slnk-shared-input-validator>
            </div>
            <div class="col-12" *ngIf="this.formSecondStep.get('sourcingType').value === 'other'">
              <textarea nbInput
                        placeholder="{{'recruitment.popup.placeholder.sourcing_note' | translate}}"
                        fullWidth
                        formControlName="sourcingNote">
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>

    <nb-card-body class="dialog-container-center" *ngIf="dialogStep === 2">
      <div class="d-flex align-items-center flex-column" *ngIf="eligibility === 'valid'">
        <h4 style="color: white; text-transform: uppercase; font-weight: bold">{{'recruitment.popup.question_eligibility' | translate}}</h4>
        <div class="container">
          <div class="d-flex justify-content-center align-items-center">
            <img src="assets/images/check-symbol.png" style="height: 33px">
            <p style="color: white; font-weight: bold; font-size: 18px;" class="ml-2">{{'recruitment.popup.eligibility_valid' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center flex-column" *ngIf="eligibility === 'invalid'">
        <h4 style="color: white; text-transform: uppercase; font-weight: bold">{{'recruitment.popup.question_eligibility' | translate}}</h4>
        <div class="container">
          <div class="d-flex justify-content-center align-items-center">
            <img src="assets/images/error-symbol.png" style="height: 33px" alt="error">
            <p style="color: white; font-weight: bold; font-size: 18px;" class="ml-2">{{'recruitment.popup.eligibility_invalid' | translate}}</p>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="showAutoentrepreneurCreationMessage()">
            <p style="color: white; font-size: 16px; font-weight: 600;" class="mt-2">
              {{'recruitment.popup.eligibility_invalid_reasons.autoentrepreneur_reason' | translate}}<br>
              {{'recruitment.popup.eligibility_invalid_reasons.autoentrepreneur_proposition' | translate}}:
            </p>
            <a href="https://www.portail-autoentrepreneur.fr/lp/partenaires/popvalet"
               class="mt-2 text-white font-weight-bold"
               style="font-size: 18px;">
              Pop Valet & Portail Auto-Entrepreneur - Devenez Auto-Entrepreneur
            </a>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="showEligibilityInvalidMessage()">
            <p style="color: white; font-size: 16px; font-weight: 600;" class="mt-2">
              {{'recruitment.popup.eligibility_invalid_reasons.reasons' | translate}} :<br>
            </p>
            <p style="color: white; font-size: 16px; font-weight: 600;" class="mt-2 w-100">
              <span *ngIf="this.candidate.hasAutoentrepreneurStatus === false" class="mb-2">
                - {{'recruitment.popup.eligibility_invalid_reasons.autoentrepreneur_status' | translate}}
                <br>
              </span>
              <span *ngIf="this.candidate.drivingLicensePoints < 8" class="mb-2">
                - {{'recruitment.popup.eligibility_invalid_reasons.driving_license_points' | translate}}
              </span>
              <br>
              <span *ngIf="this.candidate.drivingLicenseAge === 'lessThan3' || this.candidate.drivingLicenseAge === 'lessThan5'" class="mb-2">
                - {{'recruitment.popup.eligibility_invalid_reasons.driving_license_age' | translate}}
              </span>
              <br>
              <span *ngIf="this.candidate.drivingLicenseType === 'foreign'" class="mb-2">
                - {{'recruitment.popup.eligibility_invalid_reasons.driving_license_type' | translate}}
              </span>
            </p>
            <p style="color: white; font-size: 16px; font-weight: 600;" class="mt-2">
              {{'recruitment.popup.eligibility_invalid_reasons.autoentrepreneur_proposition' | translate}}:
            </p>
            <a href="https://www.portail-autoentrepreneur.fr/lp/partenaires/popvalet"
               class="mt-2 text-white font-weight-bold"
               style="font-size: 18px;">
              Pop Valet & Portail Auto-Entrepreneur - Devenez Auto-Entrepreneur
            </a>
          </div>
        </div>
      </div>
    </nb-card-body>
  </ng-container>

  <nb-card-footer class="d-flex justify-content-center eligibility-footer-buttons">
    <button nbButton class="popup-button ml-2"
            *ngIf="dialogStep === 0 && !blockedValet"
            (click)="nextStep()">{{'recruitment.popup.button_next' | translate}}</button>

    <button nbButton class="popup-button ml-2"
            *ngIf="dialogStep === 1 && retry === false"
            (click)="previousStep()">{{'recruitment.popup.button_previous' | translate}}</button>
    <button nbButton class="popup-button eligibility-create-button ml-2"
            *ngIf="dialogStep === 1 && retry === false"
            [disabled]="isSubmitting"
            [nbSpinner]="isSubmitting"
            (click)="createAccountOrRetry()">{{'recruitment.popup.button_create' | translate}}</button>
    <button nbButton class="popup-button eligibility-create-button ml-2"
            *ngIf="dialogStep === 1 && retry === true"
            [disabled]="isSubmitting"
            [nbSpinner]="isSubmitting"
            (click)="createAccountOrRetry()">{{'recruitment.popup.button_test' | translate}}</button>
    <button nbButton class="popup-button eligibility-create-button ml-2"
            *ngIf="showConnectButton && (this.dialogStep !== 2)"
            (click)="showConnexionDialog()">{{'recruitment.popup.button_connexion' | translate}}</button>

    <button nbButton class="popup-button start-recruitment"
            *ngIf="(dialogStep === 2) && (eligibility === 'valid') && this.retry === false"
            (click)="startRecruitmentProcess()">{{'recruitment.popup.button_recruitment_process' | translate}}</button>
    <button nbButton class="popup-button start-recruitment-mobile"
            *ngIf="(dialogStep === 2) && (eligibility === 'valid') && this.retry === false"
            (click)="startRecruitmentProcess()">{{'recruitment.popup.button_recruitment_process_mobile' | translate}}</button>

    <button nbButton class="popup-button start-recruitment"
            *ngIf="(dialogStep === 2) && (eligibility === 'valid') && this.retry === true"
            (click)="restartRecruitmentProcess()">{{'recruitment.popup.button_recruitment_process' | translate}}</button>
    <button nbButton class="popup-button start-recruitment-mobile"
            *ngIf="(dialogStep === 2) && (eligibility === 'valid') && this.retry === true"
            (click)="restartRecruitmentProcess()">{{'recruitment.popup.button_recruitment_process_mobile' | translate}}</button>

    <button nbButton class="popup-button"
            *ngIf="(dialogStep === 2) && (eligibility === 'invalid')"
            (click)="closeDialog()">{{'recruitment.popup.button_close_popup' | translate}}</button>
  </nb-card-footer>

</nb-card>
