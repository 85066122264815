import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    NbThemeModule,
    NbLayoutModule,
    NbToastrModule,
    NbIconModule,
    NbMenuModule,
    NbMenuService,
    NbDialogModule,
    NbDatepickerModule,
    NbButtonModule,
    NbButtonGroupModule,
    NbOptionModule,
    NbSelectModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './shared/store/user/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './shared/store/user/auth.effect';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './shared/interceptors/jwt-interceptor';
import { CityEffects } from './shared/store/city/city.effect';
import { citiesReducer } from './shared/store/city/city.reducer';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerInterceptor } from './shared/interceptors/error-handler-interceptor';
import { NbMomentDateModule } from '@nebular/moment';
import {NgCircleProgressModule} from 'ng-circle-progress';
import { DragulaModule } from 'ng2-dragula';
import { PvRecruitmentComponent } from './pv-modules/pv-recruitment/pv-recruitment.component';
import {VideoRecordingService} from './pv-modules/pv-recruitment/process-steps/recruitement-interview-step/video-recording.service';
import { FormBuilder } from '@angular/forms';
import {SharedModule} from './shared/shared.module';
import {CommonAutocompleteModule} from './common-modules/common-autocomplete/common-autocomplete.module';
import { CommonNewsDialogComponent } from './common-modules/common-news-dialog/common-news-dialog.component';
import {CommonCardModule} from './common-modules/common-card/common-card.module';
import { CommonAnomalyTreatInfractionDialogComponent } from './common-modules/common-anomalies/components/common-anomaly-profile-component/common-anomaly-treat-infraction-dialog/common-anomaly-treat-infraction-dialog.component';
import { CommonAnomalyTreatAccidentDialogComponent } from './common-modules/common-anomalies/components/common-anomaly-profile-component/common-anomaly-treat-accident-dialog/common-anomaly-treat-accident-dialog.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {PvRecruitmentModule} from './pv-modules/pv-recruitment/pv-recruitment.module';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import { CommonFirstConnectionDialogComponent } from './common-modules/common-first-connection-dialog/common-first-connection-dialog.component';


registerLocaleData(localeFr, 'fr');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PvRecruitmentComponent,
    CommonNewsDialogComponent,
    CommonAnomalyTreatInfractionDialogComponent,
    CommonAnomalyTreatAccidentDialogComponent,
    CommonFirstConnectionDialogComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonAutocompleteModule,
    NbThemeModule.forRoot({name: 'default'}),
    StoreModule.forRoot({
      user: authReducer,
      cities: citiesReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([
      AuthEffects,
      CityEffects
    ]),
    NbLayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NbToastrModule.forRoot(),
    // NbOverlayModule.forRoot(),
    NbEvaIconsModule,
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbMomentDateModule,
    NgCircleProgressModule.forRoot(),
    DragulaModule.forRoot(),
    EditorModule,
    NbButtonModule,
    NbIconModule,
    NbSelectModule,
    NbOptionModule,
    CommonCardModule,
    NbButtonGroupModule,
    PvRecruitmentModule,
    AgmCoreModule
  ],
  providers: [
    NbMenuService,
    VideoRecordingService,
    FormBuilder,
    /*  {
        provide: HAMMER_GESTURE_CONFIG,
        useClass: MyHammerConfig
      },*/
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
